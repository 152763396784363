import { EDIT_TITLE, EDIT_CONTENT, FOCUS_TITLE, FOCUS_CONTENT, EDIT_URL, PICK_IMAGE, CHANGE_COLOR, ACCEPT_AD_CONFIGURATION, UPDATE_INVOICE_DATA, UPDATE_TERMS_ACCEPTANCE, SUBMIT_AD_CONFIGURATION, SELECTED_DISPLAY_COUNTRIES_CHANGED, SUBMISION_API_REPLIED } from './ActionNames'
import { maximumPendingAdConfigurationTitleLength, maximumPendingAdConfigurationTextLength, maximumImageHeight } from './../validation/adPreviewerValidation'
import { maximumPendingAdVendorNameLength, maximumPendingAdVendorSurnameLength, maximumPendingAdVendorStreetLength, maximumPendingAdVendorCityLength, maximumPendingAdVendorEmailLength, maximumPendingAdConfigurationClickUrlLength, maximumCompanyNameLength, maximumMessageLength } from './../validation/adPreviewerValidation'

import { isSuccessStatusCode } from '../services/http-service'

const restAdPreviewerReducer = (state, action) => {
  switch (action.type) {
    case EDIT_TITLE:
      if (action.title.length > maximumPendingAdConfigurationTitleLength)
        return { ...state, ['error' + capitalize(action.inputId)]: "Ten tytuł jest za długi." }
      else
        return { ...state, ['error' + capitalize(action.inputId)]: false, [action.inputId]: action.title };

    case EDIT_CONTENT:
      if (action.content.length > maximumPendingAdConfigurationTextLength)
        return { ...state, ['error' + capitalize(action.inputId)]: "Ten tekst jest za długo." }
      else
        return { ...state, ['error' + capitalize(action.inputId)]: false, [action.inputId]: action.content };

    case FOCUS_TITLE:
      return { ...state, lastFocusedTitle: action.inputId };

    case FOCUS_CONTENT:
      return { ...state, lastFocusedContent: action.inputId };

    case EDIT_URL:
      if (action.content.length > maximumPendingAdConfigurationClickUrlLength)
        return { ...state, errorClickUrl: "URL kliknięcia jest za długi." };
      else
        return { ...state, errorClickUrl: "", [action.inputId]: action.content };

    case CHANGE_COLOR:
      return { ...state, [action.inputId]: action.color };

    case PICK_IMAGE:
      if (action.imageData.height > maximumImageHeight)
        return { ...state, errorImage: "Ikona może mieć co najwyżej 50 pikseli wysokości." }
      else
        return { ...state, imageSource: action.imageData.image, imageFileExtension: action.imageData.extension, imageWidth: action.imageData.width, imageHeight: action.imageData.height };

    case UPDATE_TERMS_ACCEPTANCE:
      return { ...state, [action.inputId]: action.content };

    case UPDATE_INVOICE_DATA:
      if (action.inputId === "phoneNumber")
        return { ...state, errorPhoneNumber: "", [action.inputId]: action.content };

      if (action.inputId === "nipNumber")
        return { ...state, errorNip: "", [action.inputId]: action.content };

      if (action.inputId === "polishPostalCode")
        return { ...state, errorPostalCode: "", [action.inputId]: action.content };

      if (action.inputId === "email") {
        if (action.content.length > maximumPendingAdVendorEmailLength)
          return { ...state, errorEmail: "E-mail jest za długi." };
        else
          return { ...state, errorEmail: "", [action.inputId]: action.content };
      }

      if (action.inputId === "companyName") {
        if (action.content.length > maximumCompanyNameLength)
          return { ...state, errorCompanyName: "Nazwa firmy jest za długa." };
        else
          return { ...state, errorCompanyName: "", [action.inputId]: action.content };
      }

      if (action.inputId === "firstName") {
        if (action.content.length > maximumPendingAdVendorNameLength)
          return { ...state, errorName: "Imię jest za długie." };
        else
          return { ...state, errorName: "", [action.inputId]: action.content };
      }

      if (action.inputId === "surname") {
        if (action.content.length > maximumPendingAdVendorSurnameLength)
          return { ...state, errorSurname: "Nazwisko jest za długie." };
        else
          return { ...state, errorSurname: "", [action.inputId]: action.content };
      }

      if (action.inputId === "street") {
        if (action.content.length > maximumPendingAdVendorStreetLength)
          return { ...state, errorStreet: "Ulica i numer domu/mieszkania są za długie." };
        else
          return { ...state, errorStreet: "", [action.inputId]: action.content };
      }

      if (action.inputId === "city") {
        if (action.content.length > maximumPendingAdVendorCityLength)
          return { ...state, errorCity: "Miasto jest za długie" };
        else
          return { ...state, errorCity: "", [action.inputId]: action.content };
      }

      if (action.inputId === "message") {
        if (action.content.length > maximumMessageLength)
          return { ...state, errorMessage: "Wiadomość jest za długa." };
        else
          return { ...state, errorMessage: "", [action.inputId]: action.content };
      }

      break;

    case ACCEPT_AD_CONFIGURATION:
      let errorClickUrl = "";
      let errorDisplayCountries = "";

      let isAdConfigured = true;

      if (!state.clickUrl) {
        isAdConfigured = false;
        errorClickUrl = "URL kliknięcia jest wymagany.";
      }
      else if (!isValidURL(state.clickUrl)) {
        isAdConfigured = false;
        errorClickUrl = "URL kliknięcia jest nieprawidłowy. Poprawny URL zaczyna się od http:// lub https://.";
      }

      if (state.selectedDisplayCountriesKeys.length === 0) {
        isAdConfigured = false;
        errorDisplayCountries = "Musisz wybrać co najmniej jeden kraj wyświetlania.";
      }

      if (state.imageSource) {
        action.history.push('/invoice');
        return { ...state, isAdConfigured: isAdConfigured, errorClickUrl: errorClickUrl, errorImage: "", errorTitle1: "", errorContent1: "" }
      }
      else if (isAtLeastOneNotEmpty([state.title1, state.title2]) && isAtLeastOneNotEmpty([state.content1, state.content2, state.content3, state.content4])) {
        action.history.push('/invoice');
        return { ...state, isAdConfigured: isAdConfigured, errorClickUrl: errorClickUrl, errorImage: "", errorTitle1: "", errorContent1: "" }
      }
      else
        return {
          ...state,
          errorImage: "Jeśli nie wpisano żadnego tytułu oraz tekstu reklamy, musi zostać wybrana ikona.",
          errorTitle1: "Jeśli nie wybrano ikony, co najmniej jeden tytuł i jednen tekst reklamy muszą zostać wpisane.",
          errorContent1: "Jeśli nie wybrano ikony, co najmniej jeden tytuł i jeden tekst reklamy muszą zostać wpisane.",
          errorClickUrl: errorClickUrl,
          errorDisplayCountries: errorDisplayCountries
        };

    case SUBMIT_AD_CONFIGURATION:
      let errorEmail = "";
      let errorPhoneNumber = "";
      let errorName = "";
      let errorSurname = "";
      let errorStreet = "";
      let errorPostalCode = "";
      let errorCity = "";
      let errorNip = "";
      let errorCompanyName = "";
      let errorPrivacyPolicy = "";
      let errorTermsOfUse = "";
      let submissionData = undefined;
      let submissionApiReply = undefined;

      if (!state.isPrivacyPolicyAccepted)
        errorPrivacyPolicy = "Polityka Prywatności musi zostać zaakceptowana.";
      else
        errorPrivacyPolicy = "";

      if (!state.isTermsOfUseAccepted)
        errorTermsOfUse = "Regulamin Usługi musi zostać zaakceptowany.";
      else
        errorTermsOfUse = "";

      if (state.nipNumber && !state.companyName)
        errorCompanyName = "Gdy podano numer NIP, nazwa firmy jest również wymagana.";
      else
        errorCompanyName = "";

      if (state.companyName && !state.nipNumber)
        errorNip = "Gdy podano Nazwę Firmy, numer NIP jest również wymagany.";
      else
        errorNip = "";

      if (state.nipNumber && state.nipNumber.length !== 13)
        errorNip = "Numer NIP jest nieprawidłowy.";
      else
        errorNip = "";

      if (state.phoneNumber && state.phoneNumber.length !== 11)
        errorPhoneNumber = "Numer telefonu jest nieprawidłowy.";
      else
        errorPhoneNumber = "";

      if (state.polishPostalCode && state.polishPostalCode.length !== 6)
        errorPostalCode = "Kod pocztowy jest nieprawidłowy.";
      else
        errorPostalCode = "";

      if (!state.email)
        errorEmail = "E-mail jest wymagany.";
      else if (!isValidEmail(state.email))
        errorEmail = "Nieprawidłowy e-mail.";
      else
        errorEmail = "";

      if (!state.firstName)
        errorName = "Imię jest wymagane.";
      else
        errorName = "";

      if (!state.surname)
        errorSurname = "Nazwisko jest wymagane.";
      else
        errorSurname = "";

      if (!state.street)
        errorStreet = "Ulica i numer domu/mieszkania są wymagane.";
      else
        errorStreet = "";

      if (!state.polishPostalCode)
        errorPostalCode = "Kod pocztowy jest wymagany.";
      else
        errorPostalCode = "";

      if (!state.city)
        errorCity = "Miasto jest wymagane.";
      else
        errorCity = "";

      if (!errorEmail && !errorPhoneNumber && !errorName && !errorSurname && !errorStreet && !errorPostalCode && !errorCity && !errorNip && !errorCompanyName && !errorPrivacyPolicy && !errorTermsOfUse) {
        submissionData = {
          "pendingAdConfiguration": {
            "title1": nullOrNonEmptyString(state.title1),
            "title2": nullOrNonEmptyString(state.title2),
            "text1": nullOrNonEmptyString(state.content1),
            "text2": nullOrNonEmptyString(state.content2),
            "text3": nullOrNonEmptyString(state.content3),
            "text4": nullOrNonEmptyString(state.content4),
            "clickUrl": nullOrNonEmptyString(state.clickUrl),
            "backgroundColor": state.backgroundColor,
            "titleFontColor": state.titleColor,
            "textFontColor": state.contentColor,
            "displayCountries": createDisplayCountriesRequestProperty(state.selectedDisplayCountriesKeys),
            "iconBinary": extractFileBase64(state.imageSource),
            "iconFileExtension": nullOrNonEmptyString(state.imageFileExtension),
            "iconWidth": state.imageWidth,
            "iconHeight": state.imageHeight
          },
          "pendingAdVendor": {
            "firstName": nullOrNonEmptyString(state.firstName),
            "lastName": nullOrNonEmptyString(state.surname),
            "street": nullOrNonEmptyString(state.street),
            "postalCode": nullOrNonEmptyString(state.polishPostalCode),
            "city": nullOrNonEmptyString(state.city),
            "vatNumber": nullOrNonEmptyString(state.nipNumber),
            "companyName": nullOrNonEmptyString(state.companyName),
            "email": nullOrNonEmptyString(state.email),
            "phoneNumber": nullOrNonEmptyString(state.phoneNumber)
          },
          "message": nullOrNonEmptyString(state.message),
          "isPrivacyPolicyAccepted": state.isPrivacyPolicyAccepted,
          "isTermsOfUseAccepted": state.isTermsOfUseAccepted
        };

        action.history.push('/submission');
      }

      return {
        ...state,
        errorEmail,
        errorPhoneNumber,
        errorName,
        errorSurname,
        errorStreet,
        errorPostalCode,
        errorCity,
        errorNip,
        errorCompanyName,
        errorPrivacyPolicy,
        errorTermsOfUse,
        submissionData,
        submissionApiReply
      };

    case SELECTED_DISPLAY_COUNTRIES_CHANGED:
      return { ...state, selectedDisplayCountriesKeys: action.selectedCountries };

    case SUBMISION_API_REPLIED:
      let isSuccessReply = isSuccessStatusCode(action.reply);

      if (isSuccessReply) {
        alert("Twoja reklama została wysłana do weryfikacji.\n\n\nPoczekaj na e-mail potwierdzający. Jeśli reklama została zaakceptowana, pozostanie jeden krok do rozpoczęcia jej wyświetlania w Camtronome. Zostanie wysłany e-mail z informacją.");

        return {
          title1: '',
          title2: '',
          content1: '',
          content2: '',
          content3: '',
          content4: '',
          clickUrl: '',
          backgroundColor: '#000000',
          titleColor: '#FFFFFF',
          contentColor: '#FFFFFF',
          firstName: '',
          surname: '',
          street: '',
          polishPostalCode: '',
          city: '',
          nipNumber: '',
          companyName: '',
          email: '',
          phoneNumber: '',
          message: '',
          isAdConfigured: false,
          isPrivacyPolicyAccepted: false,
          isTermsOfUseAccepted: false,
          lastFocusedTitle: 'title1',
          lastFocusedContent: 'content1',
          selectedDisplayCountriesKeys: ['PL']
        };
      }
      else {
        return { ...state, submissionApiReply: action.reply };
      }

    default:
      return state;
  }
};

const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

const nullOrNonEmptyString = (string) => {
  if (string === '') {
    return null;
  }
  else {
    return string;
  }
}

const isNullOrEmpty = (text) => {
  return (!text || 0 === text.length);
}

const isAtLeastOneNotEmpty = (arrayOfStrings) => {
  for (var i = 0; i < arrayOfStrings.length; i++) {
    if (!isNullOrEmpty(arrayOfStrings[i]))
      return true;
  }

  return false;
}

const isValidEmail = (email) => {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function isValidURL(url) {
  var pattern = new RegExp('^((htt)ps?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?' + // port
    '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
    '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return pattern.test(url);
};

const createDisplayCountriesRequestProperty = (keysArray) => {
  let result = '';

  for (let i = 0; i < keysArray.length; i++) {
    const element = keysArray[i];

    result += element;

    if (i < keysArray.length - 1)
      result += ',';
  }

  return result;
}

const extractFileBase64 = (file) => {
  if (file) {
    let indx = file.indexOf(',');
    let extracted = file.slice(indx + 1, file.length);
    return extracted;
  }
}

export default restAdPreviewerReducer;