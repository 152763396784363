import React from 'react';

class TitleLine extends React.Component {
    render() {
        return(
            <h3 className="ad-preview-title" style={{color: this.props.textColor}}>{this.props.text}</h3>
        );
    }
}

export default TitleLine;