export const EDIT_TITLE = 'EDIT_TITLE';
export const EDIT_CONTENT = 'EDIT_CONTENT';
export const FOCUS_TITLE = 'FOCUS_TITLE';
export const FOCUS_CONTENT = 'FOCUS_CONTENT';
export const EDIT_URL = 'EDIT_URL';
export const CHANGE_COLOR = 'CHANGE_COLOR';

export const PICK_IMAGE = 'PICK_IMAGE';

export const ACCEPT_AD_CONFIGURATION = 'ACCEPT_AD_CONFIGURATION';
export const SUBMIT_AD_CONFIGURATION = 'SUBMIT_AD_CONFIGURATION';
export const UPDATE_INVOICE_DATA = 'UPDATE_INVOICE_DATA';
export const UPDATE_TERMS_ACCEPTANCE = 'UPDATE_TERMS_ACCEPTANCE'

export const SELECTED_DISPLAY_COUNTRIES_CHANGED = 'SELECTED_DISPLAY_COUNTRIES_CHANGED'

export const SUBMISION_API_REPLIED = 'SUBMISION_API_REPLIED'
