import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router'

import { httpPost, isSuccessStatusCode } from '../services/http-service'

import { getPostSubmissionUrl } from '../services/url-provider'

import { SUBMISION_API_REPLIED } from '../reducers/ActionNames'

class SubmissionConfirmation extends React.Component {

    render() {
        const { submissionData, submissionApiReply } = this.props;
        let isSuccess = isSuccessStatusCode(submissionApiReply);

        return (
            submissionData ?
                (submissionApiReply ?
                    (isSuccess ?
                        <div className="submission">
                            <h1>Twoja reklama została wysłana do weryfikacji</h1>
                            <h3>Poczekaj na e-mail potwierdzający. Jeśli reklama została zaakceptowana, pozostanie jeden krok do rozpoczęcia jej wyświetlania w Camtronome. Zostanie wysłany e-mail z informacją.</h3>
                        </div>
                        :
                        <div className="submission">
                            <h1>Wystąpił błąd</h1>
                            <h3>Twoja reklama nie została poprawnie wysłana z powodu błędu. Proszę spróbować później.</h3>
                        </div>
                    )
                    :
                    <div className="submission">
                        <h1>Twoja reklama jest wysyłana...</h1>
                        <img src="/loader.gif" alt="loading" />
                    </div>
                )
                :
                (
                    <Redirect to="/" />
                )
        );
    }

    componentDidMount() {
        const { submissionData } = this.props;
        const { submissionApiReplied } = this.props;

        httpPost(getPostSubmissionUrl(), submissionData, function () {
            if (this.readyState === XMLHttpRequest.DONE) 
                submissionApiReplied(this.status);
        }, function() {
            submissionApiReplied(400);
        });
    }
}

const mapStateToProps = (state) => {
    return {
        submissionData: state.submissionData,
        submissionApiReply: state.submissionApiReply
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submissionApiReplied: (reply) => dispatch({ type: SUBMISION_API_REPLIED, reply }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionConfirmation)