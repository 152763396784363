import React from 'react';

export default class MultilineTextInput extends React.Component {

    render() {
        const { inputId, labelText, error, value } = this.props;
        const { onTextChange } = this.props;

        let labelClass = value ? "input-label active" : "input-label";

        return (
            <div className="input-field">
                <label htmlFor={inputId} className={labelClass}>{labelText}</label>
                <textarea id={inputId} className="materialize-textarea" value={value} onChange={(event) => onTextEntered(inputId, event.target.value, onTextChange)}></textarea>
                
                {error && <span className="span-error">{error}</span>}
            </div>
        )
    }
}

const onTextEntered = (inputId, value, onTextChange) => {
    onTextChange(inputId, value);
}