import React from 'react';

export default class InfoTooltip extends React.Component {

    render() {
        const { text } = this.props;

        return (
            <div className="tooltip-icon">
                <img className="tooltip-help-icon" src="/help-icon.png" alt="help icon" />
                <span className="tooltiptext">{text}</span>
            </div>
        );
    }
}