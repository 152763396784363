import React from 'react';

class ColorPicker extends React.Component {
    render() {
        const { inputId, labelText, value, onColorChange } = this.props;

        return (
            <div className="input-columns">
                <label className="input-label">{labelText}</label>
                <input className="input-box" type="color" value={value} onChange={(e) => onColorChange(inputId, e.target.value)} />
            </div>
        );
    }
}

export default ColorPicker;