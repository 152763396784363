import React from 'react';

class ContentLine extends React.Component {
    render() {
        return(
            <p className="ad-preview-content" style={{color: this.props.textColor}}>{this.props.text}</p>
        );
    }
}

export default ContentLine;