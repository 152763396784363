import React from 'react';

import Checkbox from '../components/checkbox';

export default class CheckboxGroup extends React.Component {

    render() {
        const { keyValueArray, acceptedKeys, onCheckboxSelectedChanged, selectAllKey, error } = this.props;
        const checkboxes = [];

        for (let i = 0; i < keyValueArray.length; i++) {
            const element = keyValueArray[i];
            checkboxes.push(
                <Checkbox key={element.key} inputId={element.key} isSelected={acceptedKeys.includes(element.key)} text={element.value} onSelectedChanged={(inputId, isSelected) => onCheckboxSelectedChangedHandler(inputId, isSelected, keyValueArray.map(kvp => kvp.key), acceptedKeys, onCheckboxSelectedChanged, selectAllKey)} />
            );
        }

        return (
            <div>
                {checkboxes}
                {error && <span className="span-error">{error}</span>}
            </div>
        );
    }
}

const onCheckboxSelectedChangedHandler = (inputId, isSelected, allKeys, acceptedKeys, onCheckboxSelectedChanged, selectAllKey) => {
    let selectedCheckboxesKeys = [...acceptedKeys];

    if (acceptedKeys && onCheckboxSelectedChanged) {
        if (inputId === selectAllKey && isSelected) {
            onCheckboxSelectedChanged(allKeys);
        }
        else {
            if (isSelected) {
                if (!selectedCheckboxesKeys.includes(inputId))
                    selectedCheckboxesKeys.push(inputId)
            }
            else {
                if (selectedCheckboxesKeys.includes(inputId))
                    selectedCheckboxesKeys = arrayRemove(selectedCheckboxesKeys, inputId);
            }

            onCheckboxSelectedChanged(selectedCheckboxesKeys);
        }
    }
}

const arrayRemove = (arr, value) => {
    return arr.filter((ele) => {
        return ele !== value;
    });
}