import React from 'react';

export default class Checkbox extends React.Component {

    render() {
        const { inputId, isSelected, onSelectedChanged, text } = this.props;

        return (
            <div className="input-box">
                <label>
                    <input type="checkbox" checked={isSelected} onChange={(event) => onSelectedChangedHandler(event.target, inputId, onSelectedChanged)}></input>
                    <span>{text}</span>
                </label>
            </div>
        );
    }
}

const onSelectedChangedHandler = (control, inputId, onSelectedChanged) => {
    if (inputId && onSelectedChanged)
        onSelectedChanged(inputId, control.checked);
}