export const httpPost = (url, body, successCallback, errorCallback) => {
    let data = JSON.stringify(body);

    let xhttp = new XMLHttpRequest();
    xhttp.timeout = 20000;
    xhttp.onreadystatechange = successCallback;
    xhttp.onerror = errorCallback;
    xhttp.open('POST', url, true);
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.send(data);
}

export const isSuccessStatusCode = (status) => {
    return status === 200 || status === 201 || status === 202 || status === 204;
}