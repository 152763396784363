import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router'

import TextInput from '../components/text-input';
import MultilineTextInput from '../components/multiline-text-input';
import TermsAccepter from '../components/terms-accepter';
import InfoTooltip from '../components/info-tooltip';

import { SUBMIT_AD_CONFIGURATION, UPDATE_INVOICE_DATA, UPDATE_TERMS_ACCEPTANCE } from '../reducers/ActionNames'

class AdVendor extends React.Component {
    render() {
        const { onSubmitClicked, onAnyFieldChange, onAccepterChange } = this.props;
        const { firstName, surname, street, polishPostalCode, city, nipNumber, companyName, email, phoneNumber, message, isPrivacyPolicyAccepted, isTermsOfUseAccepted } = this.props;
        const { errorEmail, errorPhoneNumber, errorNip, errorCompanyName, errorName, errorSurname, errorStreet, errorPostalCode, errorCity, errorMessage } = this.props;
        const { errorPrivacyPolicy, errorTermsOfUse } = this.props;
        const { isAdConfigured } = this.props;
        const { history } = this.props;

        const titlesContactDataTooltip = "E-mail jest podstawowym kanałem komunikacyjnym w przypadku wysyłania potwierdzeń zaakceptowania reklamy, danych dostępowych, czy faktur za usługę. Numer telefonu nie jest wymagany, jednak przyspieszy kontakt w przypadku problemów z Twoją reklamą.";
        const invoiceDataTooltip = "Dane do faktury wystawianej za usługę. W przypadku firm, konieczne jest podanie zarówno nazwy firmy, jak i numeru NIP. Proszę sprawdzić dane przed wysłaniem zgłoszenia.";

        return (
            isAdConfigured ? (
                <div className="ad-vendor-container">
                    <fieldset>
                        <legend>
                            <div className="legend-tooltip">
                                Dane kontaktowe
                                <InfoTooltip text={titlesContactDataTooltip} />
                            </div>
                        </legend>

                        <TextInput inputId="email" value={email} labelText="E-mail kontaktowy*: " onTextChange={onAnyFieldChange} error={errorEmail} ></TextInput>
                        <TextInput inputId="phoneNumber" value={phoneNumber} type="phoneNumber" labelText="Numer telefonu: " onTextChange={onAnyFieldChange} error={errorPhoneNumber} ></TextInput>
                    </fieldset>
                    <fieldset>
                        <legend>
                            <div className="legend-tooltip">
                                Dane do faktury
                                <InfoTooltip text={invoiceDataTooltip} />
                            </div>
                        </legend>

                        <TextInput inputId="nipNumber" value={nipNumber} type="nipNumber" labelText="NIP (dla firm): " onTextChange={onAnyFieldChange} error={errorNip} ></TextInput>
                        <TextInput inputId="companyName" value={companyName} labelText="Nazwa firmy: " onTextChange={onAnyFieldChange} error={errorCompanyName} ></TextInput>
                        <TextInput inputId="firstName" value={firstName} labelText="Imię*: " onTextChange={onAnyFieldChange} error={errorName} ></TextInput>
                        <TextInput inputId="surname" value={surname} labelText="Nazwisko*: " onTextChange={onAnyFieldChange} error={errorSurname} ></TextInput>
                        <TextInput inputId="street" value={street} labelText="Ulica i numer domu/mieszkania*: " onTextChange={onAnyFieldChange} error={errorStreet} ></TextInput>
                        <TextInput inputId="polishPostalCode" value={polishPostalCode} type="polishPostalCode" labelText="Kod pocztowy*: " onTextChange={onAnyFieldChange} error={errorPostalCode} ></TextInput>
                        <TextInput inputId="city" value={city} labelText="Miasto*: " onTextChange={onAnyFieldChange} error={errorCity} ></TextInput>
                    </fieldset>

                    <br />

                    <MultilineTextInput inputId="message" value={message} labelText="Wiadomość: " onTextChange={onAnyFieldChange} error={errorMessage}></MultilineTextInput>

                    <br />

                    <TermsAccepter inputId="isPrivacyPolicyAccepted" isAccepted={isPrivacyPolicyAccepted} onSelectedChanged={onAccepterChange} text="Akceptuję " hyperlinkText="Politykę Prywatności" hyperlinkUrl="/privacy.pdf" error={errorPrivacyPolicy} />
                    <TermsAccepter inputId="isTermsOfUseAccepted" isAccepted={isTermsOfUseAccepted} onSelectedChanged={onAccepterChange} text="Akceptuję " hyperlinkText="Regulamin Usługi" hyperlinkUrl="/tou.pdf" error={errorTermsOfUse} />

                    <div className="submit-button">
                        <button type="submit" className="btn waves-effect waves-light" onClick={(e) => onSubmitClicked(history)}>
                            Wyślij
                        </button>
                    </div>
                </div>
            )
                :
                (
                    <Redirect to="/" />
                )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        firstName: state.firstName,
        surname: state.surname,
        street: state.street,
        polishPostalCode: state.polishPostalCode,
        city: state.city,
        nipNumber: state.nipNumber,
        companyName: state.companyName,
        email: state.email,
        phoneNumber: state.phoneNumber,
        message: state.message,
        isPrivacyPolicyAccepted: state.isPrivacyPolicyAccepted,
        isTermsOfUseAccepted: state.isTermsOfUseAccepted,
        errorEmail: state.errorEmail,
        errorPhoneNumber: state.errorPhoneNumber,
        errorNip: state.errorNip,
        errorCompanyName: state.errorCompanyName,
        errorName: state.errorName,
        errorSurname: state.errorSurname,
        errorStreet: state.errorStreet,
        errorPostalCode: state.errorPostalCode,
        errorCity: state.errorCity,
        errorMessage: state.errorMessage,
        errorPrivacyPolicy: state.errorPrivacyPolicy,
        errorTermsOfUse: state.errorTermsOfUse,
        isAdConfigured: state.isAdConfigured,
        isPrivacyPolicyAccepted: state.isPrivacyPolicyAccepted,
        isTermsOfUseAccepted: state.isTermsOfUseAccepted
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAnyFieldChange: (inputId, content) => dispatch({ type: UPDATE_INVOICE_DATA, inputId, content }),
        onAccepterChange: (inputId, content) => dispatch({ type: UPDATE_TERMS_ACCEPTANCE, inputId, content }),
        onSubmitClicked: (history) => dispatch({ type: SUBMIT_AD_CONFIGURATION, history })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdVendor)