import React from 'react';
import { connect } from 'react-redux';

import AdPreview from './ad-preview'
import AdSettings from './ad-settings'

import { ACCEPT_AD_CONFIGURATION } from '../reducers/ActionNames'

class AdConfiguration extends React.Component {
    render() {
        const { onNextClicked } = this.props;
        const { history } = this.props;

        return (
            <div>
                <AdSettings />
                <AdPreview requestedImageHeight="100" />

                <div className="submit-button">
                    <button type="submit" className="btn waves-effect waves-light" onClick={(e) => onNextClicked(history)}>
                        Dalej
                    </button>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onNextClicked: (history) => dispatch({ type: ACCEPT_AD_CONFIGURATION, history })
    }
}

export default connect(null, mapDispatchToProps)(AdConfiguration)