import React from 'react';

class Branding extends React.Component {
    render() {
        return (
            <div className="branding">
                <img width="100px" src="logo.png" alt="Camtronome Advertising logo" />
    
                <div>
                  <h3>Camtronome Advertising</h3>
                  <h6>Twoje reklamy w aplikacji Camtronome</h6>
                </div>
            </div>
        );
    }
}

export default Branding;