import React from 'react';
import { connect } from 'react-redux';

import TitleLine from '../components/title-line'
import ContentLine from '../components/content-line'
import InfoTooltip from '../components/info-tooltip';

class AdPreview extends React.Component {
    render() {
        const { titles, contents, backgroundColor, titleColor, contentColor } = this.props;
        const { lastFocusedTitle, lastFocusedContent } = this.props;
        const { imageSource } = this.props;
        const { pickedImageWidth, pickedImageHeight } = this.props;
        const { requestedImageHeight } = this.props;
        const titleText = titles[lastFocusedTitle] ? titles[lastFocusedTitle] : 'Tytuł Twojej reklamy';
        const contentText = contents[lastFocusedContent] ? contents[lastFocusedContent] : 'Tekst Twojej reklamy';

        let calculatedImageWidth = calculateImageWidth(pickedImageWidth, pickedImageHeight, requestedImageHeight);

        const adPreviewTooltip = "Przybliżony wygląd reklamy na urządzeniach docelowych. Ze względu na mnogość urządzeń na rynku i ich konfiguracji, każde urządzenie będzie wyświetlać reklamę w nieco inny sposób. W szczególności nie można zagwarantować, że długi tytuł / tekst reklamy zmieści się na mniejszych ekranach w całości.";
        
        return (
            <div className="ad-preview-container">
                <fieldset>
                    <legend>
                        <div className="legend-tooltip">
                            Przybliżony wygląd reklamy
                            <InfoTooltip text={adPreviewTooltip} />
                        </div>
                    </legend>

                    <div className="ad-preview-box" style={{ background: backgroundColor }}>
                        <img width={calculatedImageWidth} height={requestedImageHeight} src={imageSource} />
                        <div className="ad-content-preview">
                            <TitleLine text={titleText} textColor={titleColor} />
                            <ContentLine text={contentText} textColor={contentColor} />
                        </div>
                    </div>
                </fieldset>
            </div>
        );
    }
}

const calculateImageWidth = (pickedImageWidth, pickedImageHeight, requestedImageHeight) => {
    if (pickedImageWidth > 0 && pickedImageHeight > 0 && requestedImageHeight > 0) {
        let imageRatio = pickedImageWidth / pickedImageHeight;
        return requestedImageHeight * imageRatio;
    }

    return 0;
}

const mapStateToProps = (state) => {
    return {
        titles: { title1: state.title1, title2: state.title2 },
        contents: { content1: state.content1, content2: state.content2, content3: state.content3, content4: state.content4 },
        backgroundColor: state.backgroundColor,
        titleColor: state.titleColor,
        contentColor: state.contentColor,
        imageSource: state.imageSource,
        pickedImageWidth: state.imageWidth,
        pickedImageHeight: state.imageHeight,
        lastFocusedTitle: state.lastFocusedTitle,
        lastFocusedContent: state.lastFocusedContent
    }
}

export default connect(mapStateToProps)(AdPreview)