import React from 'react';
import { Link } from 'react-router-dom'

export default class TermsAccepter extends React.Component {

    render() {
        const { inputId, isAccepted, onSelectedChanged, text, hyperlinkText, hyperlinkUrl, error } = this.props;

        return (
            <div className="input-box">
                <label>
                    <input type="checkbox" defaultChecked={isAccepted} onChange={(event) => onSelectedChangedHandler(event.target, inputId, onSelectedChanged)}></input>
                    <span>{text} <Link to={hyperlinkUrl} target="_blank">{hyperlinkText}</Link></span>
                </label>

                {error && <span className="span-error-right">{error}</span>}
            </div>
        );
    }
}

const onSelectedChangedHandler = (control, inputId, onSelectedChanged) => {
    if (inputId && onSelectedChanged)
        onSelectedChanged(inputId, control.checked);
}