import React from 'react';

export default class TextInput extends React.Component {

    render() {
        const { inputId, labelText, error, type, value } = this.props;
        const { onTextChange, onFocusedOrClicked } = this.props;

        let labelClass = value ? "input-label active" : "input-label";
        let inputClass = error ? "input-box validate invalid" : "input-box validate";

        return (
            <div className="input-field">
                <label htmlFor={inputId} className={labelClass} onClick={(e) => onFocusedOrClickedHandler(inputId, onFocusedOrClicked)}>{labelText}</label>
                <input type="text" id={inputId} className={inputClass} value={value} onChange={(event) => onTextEntered(event.target, type, inputId, event.target.value, onTextChange)} onFocus={(e) => onFocusedOrClickedHandler(inputId, onFocusedOrClicked)}></input>
                <span className="helper-text" data-error={error}></span>
            </div>
        )
    }
}

const onFocusedOrClickedHandler = (inputId, onFocusedOrClicked) => {
    if (inputId && onFocusedOrClicked)
        onFocusedOrClicked(inputId);
}

const onTextEntered = (control, type, inputId, value, onTextChange) => {
    if (!type)
        onTextChange(inputId, value);
    else {
        if (type === "phoneNumber") {
            value = acceptDigitsOnly(value);
            value = appendDashes(value, [ 3, 6 ]);
            value = limitLength(value, 11);
            control.value = value;
            onTextChange(inputId, value);
        }

        if (type === "nipNumber") {
            value = acceptDigitsOnly(value);
            value = appendDashes(value, [ 3, 6, 8 ]);
            value = limitLength(value, 13);
            control.value = value;
            onTextChange(inputId, value);
        }

        if (type === "polishPostalCode") {
            value = acceptDigitsOnly(value);
            value = appendDashes(value, [ 2 ]);
            value = limitLength(value, 6);
            control.value = value;
            onTextChange(inputId, value);
        }
    }
}

const acceptDigitsOnly = (value) => {
    let result = "";

    for (var i = 0; i < value.length; i++) {
        if (/^\d+$/.test(value[i]))
            result += value[i];
    }

    return result;
}

const appendDashes = (value, positions) => {
    let result = "";
    let currentPositionIndex = 0;

    for (var i = 0; i < value.length; i++) {
        if (positions[currentPositionIndex]) {
            if (i === positions[currentPositionIndex]) {
                result += "-";
                currentPositionIndex++;
            }
        }

        result += value[i];
    }

    return result;
}

const limitLength = (value, allowedLength) => {
    if (value.length < allowedLength)
        return value;
    else
        return value.substring(0, allowedLength);
}