export const maximumPendingAdConfigurationTitleLength = 47;
export const maximumPendingAdConfigurationTextLength = 63;
export const maximumPendingAdConfigurationClickUrlLength = 255;
export const maximumPendingAdVendorNameLength = 63;
export const maximumPendingAdVendorSurnameLength = 63;
export const maximumPendingAdVendorStreetLength = 127;
export const maximumPendingAdVendorCityLength = 63;
export const maximumPendingAdVendorEmailLength = 63;
export const maximumCompanyNameLength = 63;
export const maximumImageHeight = 50;
export const maximumMessageLength = 1023;

const adPreviewerValidation = values => {
    const errors = {}
    if (!values.title1) {
        errors.title1 = 'Required!'
    }
    if (!values.content1) {
        errors.content1 = 'Required!'
    }
    if (!values.firstName) {
        errors.firstName = 'Required!'
    }
    if (!values.lastName) {
        errors.lastName = 'Required!'
    }
    if (!values.email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }

    return errors;
}

export default adPreviewerValidation;