import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import restAdPreviewerReducer from './reducers/RestAdPreviewerReducer'
import * as serviceWorker from './serviceWorker';

const initState = {
    title1: '',
    title2: '',
    content1: '',
    content2: '',
    content3: '',
    content4: '',
    clickUrl: '',
    backgroundColor: '#000000',
    titleColor: '#FFFFFF',
    contentColor: '#FFFFFF',
    firstName: '',
    surname: '',
    street: '',
    polishPostalCode: '',
    city: '',
    nipNumber: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    message: '',
    isAdConfigured: false,
    isPrivacyPolicyAccepted: false,
    isTermsOfUseAccepted: false,
    lastFocusedTitle: 'title1',
    lastFocusedContent: 'content1',
    selectedDisplayCountriesKeys: [ 'PL' ]
}

const store = createStore(restAdPreviewerReducer, initState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
