import React from 'react';
import { connect } from 'react-redux';

import TextInput from '../components/text-input';
import ColorPicker from '../components/color-picker'
import FilePicker from '../components/file-picker';
import InfoTooltip from '../components/info-tooltip';

import CheckboxGroup from './checkbox-group';

import { EDIT_TITLE, EDIT_CONTENT, FOCUS_TITLE, FOCUS_CONTENT, EDIT_URL, PICK_IMAGE, CHANGE_COLOR, SELECTED_DISPLAY_COUNTRIES_CHANGED } from '../reducers/ActionNames'

class AdSettings extends React.Component {
    render() {
        const { onTitleChange, onContentChange, onColorChange,
            onTitleFocusedOrClicked, onContentFocusedOrClicked,
            onFilePickedChange,
            onClickUrlChange,
            onDisplayCountryCheckboxSelectedChanged } = this.props;
        const { backgroundColor, titleColor, contentColor } = this.props;
        const { title1, title2, content1, content2, content3, content4, clickUrl } = this.props;
        const { selectedDisplayCountriesKeys } = this.props;

        const { errorTitle1, errorTitle2 } = this.props;
        const { errorContent1, errorContent2, errorContent3, errorContent4 } = this.props;
        const { errorClickUrl } = this.props;
        const { errorImage } = this.props;
        const { errorDisplayCountries } = this.props;

        const contriesKeyValueArray = [
            { key: 'PL', value: 'Polska' },
            { key: 'US', value: 'USA' },
            { key: 'ES', value: 'Hiszpania' },
            { key: 'IT', value: 'Włochy' },
            { key: 'RU', value: 'Rosja' },
            { key: 'GB', value: 'Wielka Brytania' },
            { key: 'FR', value: 'Francja' },
            { key: 'DE', value: 'Niemcy' },
            { key: '*', value: 'Wszędzie, gdzie możliwe' },
        ];

        const titlesTooltip = "Wyświetla się wyróżnioną czcionką w górnej części reklamy. Jeśli nie wybrano ikony, co najmniej jeden tytuł jest wymagany. Więcej niż jeden tytuł losuje się z równym prawdopodobieństwem. Na każdym urządzeniu mieści się różna ilość tekstu i ikony. Ustaw kursor w polu tytułu, aby zobaczyć jego podgląd.";
        const contentsTooltip = "Wyświetla się normalną czcionką w dolnej części reklamy. Jeśli nie wybrano ikony, co najmniej jeden tekst jest wymagany. Więcej niż jeden tekst losuje się z równym prawdopodobieństwem. Na każdym urządzeniu mieści się różna ilość tekstu i ikony. Ustaw kursor w polu tekstu, aby zobaczyć jego podgląd.";
        const iconTooltip = "Wyświetla się począwszy od lewej strony reklamy, przesuwając tytuł i tekst reklamy w prawo (jeśli podano) lub na środku (jeśli nie podano tytułu i tekstu). Może być dowolnej szerokości, ale wysokości max 50 pikseli. Na każdym urządzeniu mieści się różna ilość tekstu i ikony. Dla maksymalnej kompatybilności zaleca się stosować ikony formatu jpg / png bez kanału alfa.";
        const colorsTooltip = "Kolory tła i tekstów reklamy. Niestandardowe kolory wyróżnią Twoją reklamę spośród innych.";
        const clickActionTooltip = "Link do Twojej strony / sklepu / produktu. W przypadku naciśnięcia w reklamę, na urządzeniu otworzy się domyślna przeglądarka internetowa ze stroną z linka.";
        const displayCountriesTooltip = "Docelowe grono odbiorców reklamy. W celu ochrony prywatności użytkowników Camtronome, ich przynależność do danego kraju określana jest przy użyciu ustawień regionalnych ich urządzenia.";

        return (
            <div className="ad-settings-container">
                <div className="half-screen">
                    <fieldset>
                        <legend>
                            <div className="legend-tooltip">
                                Tytuły reklamy
                                <InfoTooltip text={titlesTooltip} />
                            </div>
                        </legend>

                        <TextInput inputId="title1" value={title1} labelText="Tytuł 1: " onFocusedOrClicked={onTitleFocusedOrClicked} onTextChange={onTitleChange} error={errorTitle1}></TextInput>
                        <TextInput inputId="title2" value={title2} labelText="Tytuł 2: " onFocusedOrClicked={onTitleFocusedOrClicked} onTextChange={onTitleChange} error={errorTitle2}></TextInput>
                    </fieldset>

                    <fieldset>
                        <legend>
                            <div className="legend-tooltip">
                                Teksty reklamy
                                <InfoTooltip text={contentsTooltip} />
                            </div>
                        </legend>

                        <TextInput inputId="content1" value={content1} labelText="Tekst 1: " onFocusedOrClicked={onContentFocusedOrClicked} onTextChange={onContentChange} error={errorContent1}></TextInput>
                        <TextInput inputId="content2" value={content2} labelText="Tekst 2: " onFocusedOrClicked={onContentFocusedOrClicked} onTextChange={onContentChange} error={errorContent2}></TextInput>
                        <TextInput inputId="content3" value={content3} labelText="Tekst 3: " onFocusedOrClicked={onContentFocusedOrClicked} onTextChange={onContentChange} error={errorContent3}></TextInput>
                        <TextInput inputId="content4" value={content4} labelText="Tekst 4: " onFocusedOrClicked={onContentFocusedOrClicked} onTextChange={onContentChange} error={errorContent4}></TextInput>
                    </fieldset>
                </div>

                <div className="half-screen">
                    <fieldset>
                        <legend>
                            <div className="legend-tooltip">
                                Ikona reklamy
                                <InfoTooltip text={iconTooltip} />
                            </div>
                        </legend>

                        <FilePicker labelText="Wybierz plik ikony: " onFilePickedChange={onFilePickedChange} error={errorImage} />
                    </fieldset>

                    <fieldset>
                        <legend>
                            <div className="legend-tooltip">
                                Kolory
                                <InfoTooltip text={colorsTooltip} />
                            </div>
                        </legend>

                        <ColorPicker inputId="backgroundColor" value={backgroundColor} onColorChange={onColorChange} labelText="Tło:" />
                        <ColorPicker inputId="titleColor" value={titleColor} onColorChange={onColorChange} labelText="Tytuł:" />
                        <ColorPicker inputId="contentColor" value={contentColor} onColorChange={onColorChange} labelText="Tekst:" />
                    </fieldset>

                    <fieldset>
                        <legend>
                            <div className="legend-tooltip">
                                Akcja kliknięcia na reklamę
                                <InfoTooltip text={clickActionTooltip} />
                            </div>
                        </legend>

                        <TextInput inputId="clickUrl" value={clickUrl} labelText="URL kliknięcia: " onTextChange={onClickUrlChange} error={errorClickUrl}></TextInput>
                    </fieldset>

                    <fieldset>
                        <legend>
                            <div className="legend-tooltip">
                                Kraje wyświetlania
                                <InfoTooltip text={displayCountriesTooltip} />
                            </div>
                        </legend>

                        <CheckboxGroup keyValueArray={contriesKeyValueArray} acceptedKeys={selectedDisplayCountriesKeys} onCheckboxSelectedChanged={onDisplayCountryCheckboxSelectedChanged} selectAllKey="*" error={errorDisplayCountries} />
                    </fieldset>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        title1: state.title1,
        title2: state.title2,
        content1: state.content1,
        content2: state.content2,
        content3: state.content3,
        content4: state.content4,
        clickUrl: state.clickUrl,
        backgroundColor: state.backgroundColor,
        titleColor: state.titleColor,
        contentColor: state.contentColor,
        errorTitle1: state.errorTitle1,
        errorTitle2: state.errorTitle2,
        errorContent1: state.errorContent1,
        errorContent2: state.errorContent2,
        errorContent3: state.errorContent3,
        errorContent4: state.errorContent4,
        errorImage: state.errorImage,
        errorClickUrl: state.errorClickUrl,
        errorDisplayCountries: state.errorDisplayCountries,
        selectedDisplayCountriesKeys: state.selectedDisplayCountriesKeys
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onTitleChange: (inputId, title) => dispatch({ type: EDIT_TITLE, inputId, title }),
        onContentChange: (inputId, content) => dispatch({ type: EDIT_CONTENT, inputId, content }),
        onTitleFocusedOrClicked: (inputId) => dispatch({ type: FOCUS_TITLE, inputId }),
        onContentFocusedOrClicked: (inputId) => dispatch({ type: FOCUS_CONTENT, inputId }),
        onClickUrlChange: (inputId, content) => dispatch({ type: EDIT_URL, inputId, content }),
        onColorChange: (inputId, color) => dispatch({ type: CHANGE_COLOR, inputId, color }),
        onFilePickedChange: (imageData) => dispatch({ type: PICK_IMAGE, imageData }),
        onDisplayCountryCheckboxSelectedChanged: (selectedCountries) => dispatch({ type: SELECTED_DISPLAY_COUNTRIES_CHANGED, selectedCountries })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdSettings)