import React from 'react'

export default class FilePicker extends React.Component {

    render() {
        const { labelText, onFilePickedChange, error } = this.props;

        return (
            <div>
                <div className="input-columns">
                    <label className="input-label">{labelText}</label>
                    <input className="input-box" type="file" accept="image/png, image/jpeg" onChange={(event) => onFilePicked(event, onFilePickedChange)} />
                </div>

                {error && <span className="span-error">{error}</span>}
            </div>
        )
    }
}

const onFilePicked = (event, onFilePickedChange) => {
    if (event.target.files && event.target.files[0]) {
        let fileExtension = getFileExtension(event.target.files[0]);

        const reader = new FileReader();
        reader.onload = (fileEvent) => {
            const img = new Image();
            img.onload = (imageEvent) => {
                onFilePickedChange(createImageData(fileEvent.target.result, fileExtension, imageEvent.target.width, imageEvent.target.height));
            }
            img.src = fileEvent.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
    }
}

const getFileExtension = (file) => {
    let split = file.name.split('.');
    return split.pop();
}

const createImageData = (image, fileExtension, width, height) => {
    return {
        image: image,
        width: width,
        height: height,
        extension: fileExtension
    }
}