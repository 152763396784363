import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import './App.css';
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";

import Branding from './containers/branding'
import AdConfiguration from './containers/ad-configuration'
import AdVendor from './containers/ad-vendor'
import SubmissionConfirmation from './containers/submission-confirmation'

class App extends React.Component {
  render() {
    window.loadTest = 'LOADED';

    return (
      <div className="App">
        <Branding />

        <Router>
          <Switch>
            <Route path="/" exact component={AdConfiguration} />
            <Route path="/invoice" component={AdVendor} />
            <Route path="/submission" component={SubmissionConfirmation} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
